<main class="menu-collapsed" (window:resize)="initResponsiveElements()">
    <div ng-show="status== true">
        <app-sidebar></app-sidebar>
        <app-navbar (messageEvent)="receiveMessage($event)"></app-navbar>

        <div class="al-main" [style.margin-left]="leftMargin">
            <div *ngIf="!(removeContentFlag && sideBarToggle)" class="al-content">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
        </div>
        <go-top-button [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300" [classNames]="'custom-class'"
            [styles]="">
        </go-top-button>
        <div *ngIf="showSideMenu" class="menuListStyle">
            <div style="margin: 0 auto;text-align: left;">
                <ul class="navbar-menu animate" style="list-style-type:none;">
                    <li class="mainPlus" *ngFor="let createMenu of plusButtonMenuList" (click)="menuClick(createMenu.path)">
                        {{ 'common.'+createMenu['plusMenulabel'] | translate}}
                    </li>
                </ul>
            </div>
        </div>
        <nav id="sidePlusMenu" class="navbar navbar-fixed-left navbar-minimal animate mainPlus" role="navigation" *ngIf="enableAddButton && plusButtonMenuList && plusButtonMenuList.length > 0">
            <div class="navbar-toggler animate" (click)="toggleBottomMenu($event)">
                <span class="menu-icon" [ngStyle]="showSideMenu ? {'right': '58%'}: {'right': '63%'}">
                    <em *ngIf="!showSideMenu" class="fas fa-plus fa-3x"></em>
                    <em *ngIf="showSideMenu" class="fas fa-times fa-3x"></em>
                </span>
            </div>
        </nav>
    </div>
</main>
