
<div class="dialog-header" style="z-index: 99999;  position: relative;">
  <div  class="header bColor" >
    <h3 class="al-title">
      {{'flowBuilder.title' | translate}}
      <span *ngIf="flowName">(<span style="font-size: 18px;">{{flowName}} - {{languageUpperCase}}</span>)</span>
    </h3>

    <button mat-icon-button (click)="onCancel()" style="margin-top: 26px;" class="close-button">
      <i  style="color:black" class="fa fa-close" aria-hidden="true"></i>
    </button>
  </div>
</div>
<div class="navbar" style="z-index: 9999;">
  <div class="radio-buttons">
    <input style="margin: 1px; margin-top: -3px;" type="radio" id="radio1" checked="checked" name="radio-group" value="withcount" (change)="onRadioChange($event.target['value'])">
    <label for="radio1">{{'flowBuilder.With_node_usage_count' | translate}}</label>

    <input style="margin-left: 8px; margin-top: -3px;" type="radio" id="radio2" name="radio-group" value="withoutcount" (change)="onRadioChange($event.target['value'])">
    <label for="radio2">{{'flowBuilder.Without_node_usage_count' | translate}}</label>

  </div>
  <button style="margin:overflow 1px;" (click)="zoomOut()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.zoom_out' | translate}}">
    <i class="fa fa-search-minus" aria-hidden="true"></i>
  </button>
  <button style="margin: 1px;" (click)="zoomIn()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.zoom_in' | translate}}">
    <i class="fa fa-search-plus" aria-hidden="true"></i>
  </button>
  <button (click)="resetZoom()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.reset_zoom' | translate}}">
    <img alt="" src="./assets/images/flowbuilder-icon/zoom-reset.svg" class="img5"  >
  </button>
  <button class="fullscreen dropdown btn-color" style="padding: 0;" >
    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0px 15px;color: gray;">
      <i class="fa fa-download" aria-hidden="true" matTooltip = "{{'flowBuilder.download' | translate}}"></i>
    </a>

    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <a class="dropdown-item" (click)="downloadImage()" style="text-decoration: none; color: black;">
        <img  class="selectedLine" src="./assets/images/flowbuilder-icon/png-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.PNG' | translate}}</a>
      <a class="dropdown-item" (click)="downloadJPG()" style="text-decoration: none; color: black;">
        <img  class="selectedLine" src="./assets/images/flowbuilder-icon/jpg-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.JPG' | translate}}</a>
      <a class="dropdown-item" (click)="downloadSVG()" style="text-decoration: none; color: black;">
        <img  class="selectedLine" src="./assets/images/flowbuilder-icon/svg-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.SVG' | translate}}</a>
    </div>
  </button>
  <div class="fullscreen">
    <div class="search-box">
        <i class="fa fa-search search-icon"></i>
        <input class="search-input" placeholder="{{'common.search_for' | translate}}" type="text" [(ngModel)]="searchQuery" (ngModelChange)="this.userQuestionUpdate.next($event)" />
    </div>
</div>


  <div *ngIf="numberOfNodes">
    {{count+1}} of {{foundNode.length}}
  </div>
  <button  (click)="searchup()" class="fullscreen btn-color" >
    <i class="fa fa-arrow-down" ></i>
  </button>
  <button  (click)="searchdown()" class="fullscreen btn-color">
    <i class="fa fa-arrow-up" ></i>
  </button>
  
<span *ngIf = "withCount" class="custom-dropdown dropselect-calender" style="float: right; margin-right: 12px;">
  <select id="contactChangeDate" [(ngModel)]="model3.power" class="form-control"  (change)="changedatepicker()">
      <option value="All" i18n="@@all">{{'common.all' | translate}}</option>  
      <option value="last7" i18n="@@dateLast7days"> {{'common.last_7_days' | translate}}</option>
      <option value="last30" i18n="@@dateLast30days" selected>{{'common.last_30_days' | translate}}</option>
      <option value="choosedate" i18n="@@dateRange"> {{'common.date_rangeChoose' | translate}}</option>
  </select>
</span>
<div *ngIf="viewdropdown && withCount" >
<app-date-range-picker
[fromDate]="fromDate"
[toDate]="toDate"
(valueChange)="dateChanges($event)">
</app-date-range-picker>
</div>
  </div> 



    <div class="dialog-content"  id="dialogContent">
    
    <div id="cy" #cyContainer [ngStyle]="{'width': screenWidth + 'px'}"
    style="height: 500px;"></div>
  </div>
  <div class="navigation-container">
    <button (click)="navigateUp()" class="fullscreen nav-button">
      <i class="fas fa-angle-up"></i>
    </button>
    <div class="horizontal-buttons">
      <button (click)="navigateLeft()" class="fullscreen nav-button">
        <i class="fas fa-angle-left"></i>
      </button>
      <button (click)="navigateRight()" class="fullscreen nav-button">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
    <button (click)="navigateDown()" class="fullscreen nav-button">
      <i class="fas fa-angle-down"></i>
    </button>
  </div>
