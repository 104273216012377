<div
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
  style="
    background-image: url('assets/images/dashboard/login.avif');
    height: 100vh;
  "
  id="mainDiv"
>
  <div
    class="auth-block center-div"
    style="
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 2%;
    "
    *ngIf="showLogin"
  >
    <div style="text-align: center; padding: 2% 0%">
      <img
        alt=""
        ng-src="assets/images/dashboard/unified-ai-logo-blue.avif"
        height="50"
        width="201"
        src="assets/images/dashboard/unified-ai-logo-blue.avif"
      />
    </div>

    <div class="auth-block-body">
      <form
        name="loginForm"
        (ngSubmit)="loginForm.form.valid && sign_in(user)"
        #loginForm="ngForm"
        novalidate
      >
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
          style="padding: 5% 5%; display: grid"
        >
          <div class="form-group">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
              [class.error-textbox]="error.status === true"
            >
              <label
                style="
                  color: #333333;
                  font-family: 'WorkSansLight', sans-serif;
                  text-align: start;
                  font-size: 12px;
                  width: inherit;
                  margin-bottom: 5px;
                "
                >{{ "login.username" | translate }}</label
              >
              <div>
                <div class="input-group-prepend">
                  <span class=""
                    ><em
                      class="fas fa-user"
                      style="color: black; font-size: medium"
                    ></em
                  ></span>
                </div>
                <input id="loginUserName"
                  type="text"
                  class="form-control"
                  name="user"
                  [(ngModel)]="user.username"
                  style="
                    padding-left: 10%;
                    border-radius: 5px;
                    color: #333333 !important;
                  "
                  i18n-placeholder="@@placeHolderUserName"
                  placeholder="{{ 'login.username' | translate }}"
                  #userName="ngModel"
                  autocomplete="username"
                  required
                />
              </div>
              <span class="loginError" i18n="@@usernameRequired" *ngIf="(loginForm.submitted && userName.invalid) || (userName.errors && (userName.dirty || userName.touched))">
                {{ "login.username_required" | translate }}
              </span>
            </div>
          </div>

          <div class="form-group" style="margin-bottom: 0px">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
              [class.error-textbox]="error.status === true"
            >
              <label
                style="
                  color: #333333;
                  font-family: 'WorkSansLight', sans-serif;
                  text-align: start;
                  font-size: 12px;
                  width: inherit;
                  margin-bottom: 5px;
                "
                >{{ "login.password" | translate }}</label
              >
              <div>
                <div class="input-group-prepend">
                  <span class=""
                    ><em
                      class="fas fa-lock"
                      style="color: black; font-size: medium"
                    ></em
                  ></span>
                </div>
                <input id="loginPassword"
                  type="password"
                  class="form-control"
                  name="password"
                  [(ngModel)]="user.password"
                  style="
                    padding-left: 10%;
                    border-radius: 5px;
                    color: #333333 !important;
                  "
                  i18n-placeholder="@@placeHolderPassword"
                  placeholder="{{ 'login.password' | translate }}"
                  #password="ngModel"
                  autocomplete="current-password"
                  required
                />
              </div>
              <span class="loginError" i18n="@@passwordRequired" *ngIf="(loginForm.submitted && password.invalid) || (password.errors && (password.dirty || password.touched))">
                {{ "login.password_required" | translate }}
              </span>
            </div>
          </div>
          <div class="form-group" style="margin-top: 12px">
            <div style="width: 70px">
              <label
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
                (click)="showTemplate(userSignup)"
                style="
                  cursor: pointer;
                  font-weight: 500;
                  margin-bottom: 0px;
                  margin-top: -2%;
                  color: #333333;
                "
                name="signup"
                id="signup"
                >{{ "login.signUp" | translate }}</label
              >
            </div>
            <div style="width: 151px; float: right; margin-top: 2px" id="loginForgotPassword">
              <label
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
                (click)="showTemplate(forgotPassword)"
                style="
                  cursor: pointer;
                  font-weight: 500;
                  margin-bottom: 0px;
                  margin-top: -2%;
                  color: #333333;
                "
                name="forgetpassword"
                id="forgetpassword"
                >{{ "login.forgot_password" | translate }}</label
              >
            </div>
          </div>

          <div class="form-group" style="margin-bottom: 0px">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
              <button id="loginSubmit"
                class="btn-default btn-auth"
                type="submit"
                style="
                  background-color: #386ba7;
                  border-radius: 5px;
                  box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, 0.2),
                    0 0 14px 0 rgba(30, 135, 240, 0.3);
                "
              >
                {{ "login.title" | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="ssoEnabled">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="text-align: left;">
            <label style="cursor: pointer;font-weight: 500;margin-bottom: 0px; margin-top: 5%; color: #333333;
                text-align: left;" name="forgetpassword">{{ "login.sign_in_using" | translate }}
            </label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="padding-top: 16px;">
            <!-- Login with Google -->
            <div style="float: left;cursor: pointer;" id="googleSignin" class="googlSignin" (click)="loginUsingGoogle()">
              <em class="fa-brands fa-google" style="font-size: 22px;"></em>
            </div>
            <span class="loginError" i18n="@@error" *ngIf="googleError" style="padding: 5px;">
              {{ googleError }}
            </span>
        <!-- Login with Linkedin -->
        <div style="float: left; padding-left: 20px;">
          <div class="app-linkedin-login btn btn-linkedin" (click)="loginUsingLinkedIn()">
            <em class="fa fa-linkedin" style="font-size: 22px;"></em></div>
        </div>
      </div>
       </div>
        </div>
      </form>
    </div>

    <ng-template #forgotPassword>
      <div class="">
        <div
          class="col-md-12"
          class="modal-content"
          style="border-radius: 6px 6px 0px 0px !important"
        >
          <div class="modal-header headerModal" style="text-align: center">
            <button type="button" class="close" (click)="onCancel()">
              &times;
            </button>
            <h4 class="modal-title">
              {{ "login.forgot_password" | translate }}
            </h4>
          </div>

          <div style="padding: 2%">
            <div style="text-align: center; padding: 1% 2% 3% 2%">
              <span style="font-size: 18px">{{
                "login.reset_message" | translate
              }}</span>
            </div>
            <div class="auth-block-body" style="height: 85px; width: auto">
              <div class="col-xs-9 login-input" style="padding: 2%">
                <div class="form-group">
                  <div class="col-xs-12 login-input">
                    <input id="forgotPasswordEmail"
                      type="text"
                      class="form-control"
                      name="email"
                      [(ngModel)]="email"
                      placeholder="{{ 'common.email' | translate }}"
                      style="border-radius: 5px; color: #333333 !important"
                      autocomplete="none"
                      required
                    />
                    <span id="email_requires"
                      *ngIf="
                        forgotPasswordSubmitted &&
                        (email == '' || email == undefined || email == null)
                      "
                    >
                      {{ "login.email_required" | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-3 login-input submit" style="padding: 2%">
                <div
                  class="login-submit"
                  style="
                    padding: 0;
                    margin: 0;
                    height: auto;
                    background-color: #386ba7;
                    border-radius: 5px;
                  "
                >
                  <button id="submitForgotPassword"
                    class="btn-default btn-auth"
                    type="button"
                    (click)="submitForgotPassword()"
                  >
                    {{ "common.submit" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #userSignup>
      <div>
        <div
          class="col-md-12"
          class="modal-content size"
          style="border-radius: 10px !important"
          id="model"
          name="model"
        >
          <div
            class="modal-header headerModal"
            style="text-align: center; border-bottom: 0px"
          >
            <button type="button" class="close" (click)="onClose()">
              &times;
            </button>
            <img
              src="assets/images/dashboard/unified-ai-logo-blue.avif"
              height="40"
              alt=""
              style="margin-top: 10px"
            />
          </div>
          <form [formGroup]="UserForm" (ngSubmit)="onSignUp()">
            <div style="margin-top: 20px; margin-left: 26px">
              <div>
                <label class="signup-label" style="margin-left: 6px">{{
                  "login.licensekey" | translate
                }}</label
                ><br />
                <div style="display: flex">
                  <span
                    style="display: inline-flex; margin-left: 5px"
                    [ngStyle]="{ 'margin-bottom': showInfo ? '0px' : '55px' }"
                  >
                    <div class="form-group">
                      <div class="inputContainer">
                        <em
                          class="fas fa-key"
                          style="
                            font-size: medium;
                            margin-top: 8px;
                            margin-left: 8px;
                          "
                        ></em>
                        <input
                          type="text" id="loginLicensekey"
                          class="form-control Field"
                          name="validatekey"
                          formControlName="licensekey"
                          placeholder="{{ 'login.licensekey' | translate }}"
                          style="
                            border-radius: 5px;
                            color: #333333 !important;
                            width: 250px;
                            margin-top: -2.7rem;
                          "
                          (keydown)="verifyKey()"
                          (keyup)="verifyKey()"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div
                        *ngIf="
                          clickValidate &&
                          UserForm.controls['licensekey'].invalid
                        "
                        class="text-danger"
                      >
                        <div
                          *ngIf="
                            UserForm.controls['licensekey'].errors.required
                          "
                        >
                          {{ "login.key_required" | translate }}
                        </div>
                      </div>
                      <div
                        *ngIf="clickValidate && !showInfo"
                        class="text-danger"
                      >
                        <div
                          *ngIf="
                            clickValidate &&
                            !showInfo &&
                            UserForm.controls['licensekey'].valid
                          "
                        >
                          {{ validationMsg }}
                        </div>
                      </div>
                    </div>
                  </span>
                  <span
                    style="
                      display: inline-flex;
                      margin-left: 8px;
                      margin-right: 30px;
                    "
                  >
                    <div class="form-group">
                      <button id="licenseValidation"
                        class="form-control"
                        style="
                          background-color: #386ba7;
                          border-radius: 5px;
                          color: white;
                          width: 150px;
                          margin-top: -2.5px;
                        "
                        [disabled]="showInfo"
                        type="button"
                        (click)="licenseValidation()"
                      >
                        {{ showInfo ? "VALIDATED" : "VALIDATE" }}
                      </button>
                    </div>
                    <div *ngIf="showInfo">
                      <em
                        class="fa fa-check-circle"
                        style="
                          font-size: 15px !important;
                          padding-top: 9px;
                          margin-left: 50%;
                          color: green;
                        "
                        aria-hidden="true"
                        *ngIf="showInfo"
                      ></em>
                    </div>
                  </span>
                </div>
              </div>

              <div
                style="margin-left: 5px; margin-right: 30px"
                *ngIf="showInfo"
              >
                <div class="form-group">
                  <label class="signup-label">{{
                    "login.compnay" | translate
                  }}</label>
                  <div class="inputContainer">
                    <em
                      class="fas fa-briefcase"
                      style="
                        font-size: medium;
                        margin-top: 8px;
                        margin-left: 8px;
                      "
                    ></em>
                    <input
                      type="text" id="loginCompany"
                      class="form-control Field"
                      name="company"
                      formControlName="company"
                      placeholder="{{ 'login.compnay' | translate }}"
                      style="
                        border-radius: 5px;
                        color: #333333 !important;
                        width: 408px;
                        margin-top: -2.7rem;
                      "
                      autocomplete="none"
                      required
                      [attr.disabled]="true"
                    />
                  </div>
                </div>
              </div>

              <div style="margin-left: 5px" *ngIf="showInfo">
                <div class="form-group">
                  <label class="signup-label">{{
                    "login.user_name" | translate
                  }}</label>
                  <div class="inputContainer">
                    <em
                      class="fas fa-user"
                      style="
                        font-size: medium;
                        margin-top: 8px;
                        margin-left: 8px;
                      "
                    ></em>
                    <input
                      type="text" id="loginusername2"
                      class="form-control Field"
                      name="validatekey"
                      formControlName="username"
                      placeholder="{{ 'login.user_name' | translate }}"
                      style="
                        border-radius: 5px;
                        color: #333333 !important;
                        width: 408px;
                        margin-top: -2.7rem;
                      "
                      autocomplete="none"
                      required
                      [attr.disabled]="true"
                    />
                  </div>
                </div>
              </div>

              <div style="margin-left: 5px" *ngIf="showInfo">
                <div class="form-group">
                  <label class="signup-label">{{
                    "login.password" | translate
                  }}</label>
                  <div class="inputContainer">
                    <em
                      class="fas fa-lock"
                      style="
                        font-size: medium;
                        margin-top: 8px;
                        margin-left: 8px;
                      "
                    ></em>
                    <input id="loginPassword2"
                      type="password"
                      class="form-control Field"
                      name="password"
                      formControlName="password"
                      placeholder="{{ 'login.password' | translate }}"
                      style="
                        border-radius: 5px;
                        color: #333333 !important;
                        background-color: white;
                        width: 408px;
                        margin-top: -2.7rem;
                      "
                      autocomplete="none"
                      required
                    />
                  </div>
                  <div
                    *ngIf="submitted && UserForm.controls['password'].invalid"
                    class="text-danger"
                  >
                    <div *ngIf="UserForm.controls['password'].errors.required">
                      {{ "login.password_required" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-left: 5px" *ngIf="showInfo">
                <div class="form-group">
                  <label class="signup-label">{{
                    "login.confirm_password" | translate
                  }}</label>
                  <div class="inputContainer">
                    <em
                      class="fas fa-lock"
                      style="
                        font-size: medium;
                        margin-top: 8px;
                        margin-left: 8px;
                      "
                    ></em>
                    <input id="loginConfirmPassword"
                      type="password"
                      class="form-control Field"
                      name="password"
                      formControlName="confirm"
                      placeholder="{{ 'login.confirm_password' | translate }}"
                      style="
                        border-radius: 5px;
                        color: #333333 !important;
                        background-color: white;
                        width: 408px;
                        margin-top: -2.7rem;
                      "
                      autocomplete="none"
                      required
                    />
                  </div>
                  <div
                    *ngIf="
                      passwordValidate &&
                      UserForm.get('confirm').errors.confirmPasswordValidator
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        passwordValidate &&
                        UserForm.get('confirm').errors.confirmPasswordValidator
                      "
                    >
                      {{ "login.password_must_match" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="showInfo">
                <button id="loginSignUp"
                  class="form-control"
                  style="
                    background-color: #386ba7;
                    border-radius: 5px;
                    color: white;
                    width: 409px;
                    margin-left: 4px;
                    margin-top: 35px;
                  "
                  type="submit"
                >
                  {{ "login.sign_up" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</div>
