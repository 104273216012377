<div *ngIf="(chooseType.name !== 'conversation_segregation') && (chooseType.name !== 'static_segregation') && (chooseType.name !== 'dummy')" [className]="chooseType.className" matTooltipPosition="left" [matTooltip]="chooseType.tooltip" [ngClass]="{'is-flipped' : chooseType.value == selectedFlipCard.value, 'chooseType.className' : chooseType.className != selectedFlipCard.value}">
  <div class="body-panel" *ngIf="chooseType.value != selectedFlipCard.value">
    <h2 class="panel-h5">
      <img *ngIf=" chooseType.name != 'avg_conversation' && chooseType.name != 'handling' && chooseType.name != 'response' && chooseType.name != 'messages'" class="menu-side-icon" [src]="getMenuIcon(chooseType['label'])" style="cursor:pointer;filter: invert(100%);height :15px;width:15px;color:#386BA7;" alt="" />
      &nbsp;<span *ngIf="chooseType.name == 'handling' || chooseType.name == 'messages'|| chooseType.name == 'response'">%&nbsp;&nbsp;</span>{{chooseType.value.toUpperCase()}}
    </h2>
    <div class="text-panel">
      <p *ngIf ="type == 'messages' " class="">{{value.toFixed(0)}}{{chooseType.valueSymbol}}<span *ngIf="value !== 0" style="font-size:14px;">{{'dashboard._session' | translate}}</span></p>
      <p *ngIf ="type =='accuracy' || type == 'activeSessions' || type == 'activeChannels' || type == 'activeConversation' || type == 'activeUsers' || type == 'conversationElements' || type == 'contactLists'" class="">{{value}}{{chooseType.valueSymbol}}<span *ngIf="(type == 'contactLists' && value !== 0)" style="font-size:15px;"> {{'dashboard.contacts' | translate}}</span></p>

      <p *ngIf ="type == 'longestHandling'" class="">{{value > 0 ? (value / 60).toFixed(2) : value}}<span style="font-size:15px;"> {{'dashboard.mins' | translate}}</span></p>
      <p *ngIf ="type == 'handling'" class="">{{value > 0 ? (value / 60).toFixed(2) : value}}<span *ngIf="value == 0" style="font-size:14px;"> {{'dashboard.mins' | translate}}</span><span *ngIf="value !== 0" style="font-size:14px;">  {{'dashboard.mins_session' | translate}}</span></p>
      <p *ngIf ="type =='response'" class="">{{value > 0 ? value.toFixed(0) : value}}<span *ngIf="value == 0" style="font-size:14px;">  {{'dashboard.secs' | translate}}</span><span *ngIf="value !== 0" style="font-size:14px;"> {{'dashboard.secs_session' | translate}}</span></p>
      <p *ngIf="type == 'messageTemplates'">{{ value.approvedCount ?(value.approvedCount + value.rejectedCount) :0 }}{{chooseType.valueSymbol}}</p>

      <p *ngIf="type == 'messageTemplates'" style="font-size:12px;filter: saturate(60%);font-family: 'WorkSansLight', sans-serif;font-style:italic;">
        <br/>
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;">{{value.approvedCount}} </span> {{'dashboard.approved' | translate}}
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;"> | {{value.rejectedCount}} </span> {{'dashboard.non_approved' | translate}}
      </p>
      <p *ngIf="type == 'conversationElements'" style="font-size:12px;filter: saturate(60%);font-family: 'WorkSansLight', sans-serif;font-style:italic;">
        <br/>
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;">{{value}} </span>{{'dashboard.trained' | translate}}
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;"> | 0 </span>{{'dashboard.untrained' | translate}}
      </p>
      <p *ngIf="type == 'conversation'">{{value.totalcount}}</p>
      <p *ngIf="type == 'conversation' && nlpwithAgent" style="font-size:12px;filter: saturate(60%);font-family: 'WorkSansLight', sans-serif;font-style:italic;">
        <br/>
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;">{{value.botsessionCount}} </span> {{'dashboard.bot' | translate}}
        <span style="font-size:25px;font-style:initial;font-family: 'WorkSansLight', sans-serif;"> | {{value.agentsessionCount}} </span> {{'dashboard.agentHandover' | translate}}
      </p>
    </div>
  </div>
  <div class="body-panel" *ngIf="chooseType.value == selectedFlipCard.value">
    <h2 class="panel-h5">
      <img *ngIf="chooseType.name != 'longestHandling' && chooseType.name != 'avg_conversation' && chooseType.name != 'handling' && chooseType.name != 'conversation' && chooseType.name != 'response' && chooseType.name != 'messages' && chooseType.name != 'activeSessions'" class="menu-side-icon" [src]="getMenuIcon(chooseType.value)" style="cursor:pointer;filter: invert(100%);height :15px;width:15px;color:#386BA7;" alt="" />
      &nbsp;{{chooseType.value.toUpperCase()}}
    </h2>
    <div class="text-panel" style="width:100%;display: inline-flex;height:25px;">
      <label>{{'common.name' | translate}}: &nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input id="overview-cardName" style="background:white;border:none;outline:none;cursor:auto;" type="text" [(ngModel)]="chooseType.value" (click)="$event.stopPropagation();">
    </div>
    <div class="text-panel" style="width:100%;display: inline-flex;height:35px;">
      <label>{{'dashboard.tooltip' | translate}}: &nbsp;&nbsp;</label>
      <textarea type="text" style="cursor:auto;height: 100%;padding-top: 0px !important; resize: none;box-shadow:none !important;width: 100%;font-size: 12px;border-radius:3px;outline:none;" [(ngModel)]="chooseType.tooltip" (click)="$event.stopPropagation();"></textarea>
    </div>
    <div style="display:block; text-align:end;height:30px;margin-top:1%;">
      <button id="overview-cardSaveP" class="btn" type="submit" style="border-radius: 5px;background-color:transparent;color:white;width: 10%;padding:1px;margin-right:1%;" title="Save" (click)="$event.stopPropagation();" ><em style="color:white;cursor:pointer;font-size:15px;" class="fa fa-check"></em></button>
      <button id="overview-cardCancle" type="reset" class="btn btn-default" style="border-radius: 5px;color:white;background-color:transparent;width: 10%;padding:1px;border:none;" title="Cancel" (click)="$event.stopPropagation();cancelFlip()" ><em style="color:white;cursor:pointer;font-size:15px;" class="fa fa-close"></em></button>
    </div>
  </div>
  <em style="display:none" *ngIf="selectedFlipCard.value == '' && (chooseType.name == 'longestHandling' || chooseType.name == 'avg_conversation' || chooseType.name == 'handling' || chooseType.name == 'conversation' || chooseType.name == 'response' || chooseType.name == 'messages' || chooseType.name == 'activeSessions')" (click)="$event.stopPropagation();flipCard(chooseType)" class="flip fa fa-cog"></em>
</div>

<div *ngIf="chooseType.name == 'conversation_segregation'" [className]="chooseType.className">
  <div class="body">
    <div class="title">{{chooseType.value}}</div>
  </div>
  <div class="header">
    <div class="left">
      <div class="count">{{value}}{{chooseType.valueSymbol}}</div>
      <div class="title">{{'common.bot' | translate}}</div>
    </div>
    <div class="right">
      <div class="count">{{value1}}{{chooseType.valueSymbol}}</div>
      <div class="title">{{'common.agent_handover' | translate}}</div>
    </div>
  </div>
</div>

<div *ngIf="chooseType.name == 'static_segregation'" [className]="chooseType.className">
  <div class="body">
    <div class="title">{{chooseType.value}}</div>
  </div>
  <div class="header">
    <div class="left">
      <div class="count">{{value}}{{chooseType.valueSymbol}}</div>
      <div class="title">{{'dashboard.static_FAQs' | translate}}</div>
    </div>
    <div class="right">
      <div class="count">{{value1}}{{chooseType.valueSymbol}}</div>
      <div class="title">{{'dashboard.questions_and_flows' | translate}}</div>
    </div>
  </div>
</div>
