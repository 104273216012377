
<!-- PROVIDE LOGIN DETAILS -->
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="background-image:url('assets/images/dashboard/login.avif'); height:100vh;  overflow: auto;
">
    <div class="auth-block center-div" style="text-align:center;background-color:white; border-radius:10px;padding:2%;" *ngIf="!isLoggedIn && existingAccounts.length == 0 && !normalRegister && validUser" >
      <h1 style="color:#386BA7;margin:0px;" *ngIf="bizName && validUser"> {{'login.welcome' | translate}} {{ bizName }} {{'login.withUIB' | translate}} </h1>
      <p style="color:#386BA7;" *ngIf="bizName && validUser">{{'login.signin_Info' | translate}}</p>

      <div *ngIf="!bizLogo && validUser" style="text-align: center;padding: 0px 0px 40px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <div *ngIf="bizLogo && validUser" style="text-align: center;padding: 0px;width: 100%;">
          <img alt="" height="60" src={{bizLogo}}>
      </div>
    <div class="auth-block-body" *ngIf="validUser">
      <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="!addProjectUESuccess"
      style="display: inline-flex;padding: 10px 0px 0px 0px;cursor: pointer;font-size: 16px;">
      <div (click)="selectedABCChange('abcUE')" style="padding: 1% 3% 1% 0%;">
        <input style="cursor: pointer; display: inline;" type="radio" value="abcUE" id="abcUE"
          class="radio_activity" [checked]="selectedABCOption === 'abcUE'" />
        &nbsp;<label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 15px;width: inherit;margin-bottom: 0px;">{{'login.abcUERadio' | translate}}</label>
      </div>
      <div (click)="selectedABCChange('abcChatbot')" style="padding: 1%;">
        <input style="cursor: pointer; display: inline;" type="radio" value="abcChatbot" id="abcChatbot"
          class="radio_activity" [checked]="selectedABCOption === 'abcChatbot'" />
        &nbsp;<label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 15px;width: inherit;margin-bottom: 0px;">{{'login.abcChatbotRadio' | translate}}</label>
      </div>
    </div>
    <div *ngIf="!addProjectUESuccess">
      <form [formGroup]="loginForm" (submit)="onSubmit()" novalidate *ngIf="selectedABCOption === 'abcChatbot'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="padding: 3%;display:grid;">
          <div class="form-group">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" [class.error-textbox]="error">
              <label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 12px;width: inherit;margin-bottom: 0px;">Username</label>
              <div>
                <div class="input-group-prepend">
                  <span class="input-group-text"><em class="fas fa-user" style="color:black;font-size: medium;"></em></span>
                </div>
                <input type="text" class="form-control" name="user" formControlName="username" style="padding-left: 10%;border-radius: 5px;color:#333333 !important;margin:0px !important;" placeholder="{{'login.username' | translate}}" required>
                <span i18n="@@usernameRequired" *ngIf="(this.submitted && this.f.username.errors && this.f.username.errors.required)" style="bottom: -20px !important;left: 17px !important;">
                  {{'login.username_required' | translate}}
                </span>
              </div>
            </div>
          </div>
          <div class="form-group" style="margin-bottom:0px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" [class.error-textbox]="error">
              <label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 12px;width: inherit;margin-bottom: 0px;">Password</label>
              <div>
                <div class="input-group-prepend">
                  <span class="input-group-text"><em class="fas fa-lock" style="color:black;font-size: medium;"></em></span>
                </div>
                <input type="password" class="form-control" name="password" formControlName="password" style="padding-left: 10%;border-radius: 5px;color:#333333 !important;margin:0px !important;" placeholder="{{'login.password' | translate}}" required>
                <span i18n="@@passwordRequired" *ngIf="(this.submitted && this.f.password.errors && this.f.password.errors.required)" style="bottom: -20px !important;left: 17px !important;">
                  {{'login.password_required' | translate}}
                </span>
              </div>
            </div>
          </div>

          <div class="form-group" style="margin-right:0%;margin-bottom:20px;margin-top:5%;">
            <label class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="cursor:pointer;font-weight:500;margin-bottom:0px;color:#333333;"> {{'login.No_account' | translate}} <a  class="cancelButton" style="background: transparent;border-radius: 0px;border: none;color: #386BA7;text-decoration: none;padding: 0%;box-shadow: none;" (click)="showSignUpForm()">{{'login.here' | translate}}</a> {{'login.createOne' | translate}}</label>
          </div>

          <div class="form-group" style="margin-bottom:0px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" >
              <button class="btn-default btn-auth" type="submit" style="background-color: #386BA7;border-radius:5px;box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, .2), 0 0 14px 0 rgba(30, 135, 240, .3);">
                {{'login.title' | translate}}
              </button>
            </div>
          </div>  
        </div>
      </form>
      <form (submit)="onSubmitUE()" novalidate *ngIf="selectedABCOption === 'abcUE'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="padding: 3%;display:grid;">
          <div class="form-group">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" [class.error-textbox]="error" style="padding-top: 20px;">
              <label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 12px;width: inherit;margin-bottom: 0px;">{{'login.abcCouponCode' | translate}}</label>
              <div>
                <input type="text" class="form-control" name="abcCouponCode" [(ngModel)]="abcCouponCode" style="padding-left: 1%;border-radius: 5px;color:#333333 !important;margin:0px !important;" placeholder="{{'login.abcCouponCode' | translate}}" required>
                <span i18n="@@usernameRequired" *ngIf="(this.submitted && !this.abcCouponCode)" style="bottom: -20px !important;left: 17px !important;">
                  {{'login.coupon_required' | translate}}
                </span>
              </div>
            </div>
          </div>
          <div class="form-group" style="margin-bottom:0px;margin-top:10px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" >
              <button class="btn-default btn-auth" type="submit" style="background-color: #386BA7;border-radius:5px;box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, .2), 0 0 14px 0 rgba(30, 135, 240, .3);">
                {{'common.submit' | translate}}
              </button>
            </div>
          </div>  
        </div>
      </form>
      <div class="errormsg" style="margin-top:0px;" *ngIf="error">
        <em class="fa fa-warning" style="color:red"></em>
        <span>{{error}}</span>
      </div>
    </div>
        <div *ngIf="addProjectUESuccess" style="margin-top: 40px;">
        <p style="color: green;font-family: 'WorkSansLight', sans-serif;text-align: center;font-size: 18px;padding: 3%;display:grid;font-weight: 600;"> <span>{{'login.abcURSuccess' | translate}}</span></p>
      </div>
    </div>
  </div>
    <div class="auth-block center-div box" style="width: 70%;" *ngIf="!validUser">
      <h1 style="color:#386BA7;margin:0px;" *ngIf="bizName"> {{'login.welcome' | translate}} {{ bizName }} {{'login.withUIB' | translate}} </h1>
      <p style="color:#386BA7;" *ngIf="bizName">{{'login.signin_Info' | translate}}</p>

      <div *ngIf="!bizLogo" style="text-align: center;padding: 0px 0px 40px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <div *ngIf="bizLogo" style="text-align: center;padding: 0px 0px 20px 0px;width: 100%;">
          <img alt="" height="60" src={{bizLogo}}>
      </div>

      <p style="color:#386BA7;" *ngIf="!bizName">{{'login.fields_mandatory' | translate}}</p>
      <div class="col-md-12 col-sm-12 col-xs-12 main">
        <div class="errormsg" style="width:100%;padding:0px;margin-top:0px;height:30px;margin-bottom: 0px !important;display: block;background-color:white;" *ngIf="signuperror">
          <p>{{signuperror}}</p>
        </div>
        <form [formGroup]="signupForm" (ngSubmit)="onSubmitSignUp()" style="display: block;margin: auto; text-align:center;" novalidate>
          <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 5px 10px;">
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="text" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="email" formControlName="email" placeholder="Email" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.email.errors && s.email.errors.required)">
                {{'login.email_required' | translate}}
              </span>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.email.errors && s.email.errors.invalidEmail)">
                {{'login.must_be_valid' | translate}}
              </span>
            </div>  
          
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="text" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="name" formControlName="name" placeholder="{{'common.name' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.name.errors && s.name.errors.required)">
                {{'login.name_required' | translate}}
              </span>
            </div>
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="password" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="password" formControlName="password" placeholder="{{'login.password' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.password.errors && s.password.errors.required)">
                {{'login.password_required' | translate}}
              </span>
            </div>
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="password" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="confirmpassword" formControlName="confirmpassword" placeholder="{{'password.confirm_password_placeholder' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.confirmpassword.errors && s.confirmpassword.errors.MatchPassword)">
                {{'password.passwords_must_match' | translate}}
              </span>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12" style="text-align:center;padding: 0% 6%;display: inline-flex;"> 
            <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;margin-right:1%;text-align:end;">
              <button class="cancelButton" style="padding:3% 5%;width:65%; text-decoration:none;" (click)="cancel()">{{'common.cancel' | translate}}</button>
            </label>
            <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;text-align:left;">
              <button class="connectButton" style="padding:3% 5%;width:65%;" type="submit">{{'common.submit' | translate}}</button>
            </label>
          </div>
        </form>
      </div>
    </div>

  <!-- NORMAL REGISTER DETAILS -->
  <div class="auth-block center-div" style="text-align:center;width: 70%;" *ngIf="!isLoggedIn && existingAccounts.length == 0 && normalRegister && validUser">
    <div class="auth-block center-div box">
      <div style="text-align: center;padding: 0px 0px 40px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>
      <p style="color:#386BA7;">{{'login.fields_mandatory' | translate}}</p>

      <div class="col-md-12 col-sm-12 col-xs-12 main">
        <div class="errormsg" style="width:100%;padding:0px;margin-top:0px;height:30px;margin-bottom: 0px !important;display: block;background-color:white;" *ngIf="signuperror">
          <p>{{signuperror}}</p>
        </div>
        <form [formGroup]="signupForm" (ngSubmit)="onSubmitSignUp()" style="display: block;margin: auto; text-align:center;" novalidate>
          <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 5px 10px;">
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="text" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="email" formControlName="email" placeholder="{{'login.email_placeholder' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.email.errors && s.email.errors.required)">
                {{'login.email_required' | translate}}
              </span>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.email.errors && s.email.errors.invalidEmail)">
                {{'login.must_be_valid' | translate}}
              </span>
            </div>  
          
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="text" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="name" formControlName="name" placeholder="{{'common.name' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.name.errors && s.name.errors.required)">
                {{'login.name_required' | translate}}
              </span>
            </div>
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="password" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="password" formControlName="password" placeholder="{{'login.password' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.password.errors && s.password.errors.required)">
                {{'login.password_required' | translate}}
              </span>
            </div>
            <div class="form-group">
              <div class="login-input" style="display: block;margin: auto;text-align: center;width: 60%;">
                <input type="password" style="box-shadow: none !important;border-radius:5px;" class="form-control" name="confirmpassword" formControlName="confirmpassword" placeholder="{{'password.confirm_password_placeholder' | translate}}" required>
              </div>
              <span style="display: block;margin: auto;text-align: start;width: 60%;color:red;margin-top:-1%;" *ngIf="(submitted && s.confirmpassword.errors && s.confirmpassword.errors.MatchPassword)">
                {{'password.passwords_must_match' | translate}}
              </span>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12" style="text-align:center;padding: 0% 6%;display: inline-flex;"> 
            <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;margin-right:1%;text-align:end;">
              <button class="cancelButton" style="padding:3% 5%;width:65%; text-decoration:none;" (click)="cancel()">{{'common.cancel' | translate}}</button>
            </label>
            <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;text-align:left;">
              <button class="connectButton" style="padding:3% 5%;width:65%;" type="submit">{{'common.submit' | translate}}</button>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- CONNECT TO SINGLE ACOOUNT -->
  <div class="auth-block center-div box" style="padding:2% 10%;" *ngIf="selectedAccountToConnect == null && existingAccounts.length == 1">
    <div class="main">
      <div style="text-align: center;padding: 0px 0px 0px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <h1>{{'login.account_to_Connect' | translate}}</h1>
      <p>{{'login.like_connect' | translate}}</p>

      <div class="col-md-12 col-sm-12 col-xs-12 accountDetailsStyle" style="width:100%;">
        <label class="col-md-5 col-sm-5 col-xs-5" style="display:inline-grid;text-align:start;">
          <span>{{'login.Org_name' | translate}}:</span>
          <span *ngIf="!normalRegister">{{'login.BusinessID_msg' | translate}}:</span>
          <span *ngIf="normalRegister">{{'login.projectId' | translate}}:</span>
        </label>
        <label class="col-md-7 col-sm-7 col-xs-7" style="display:inline-grid;text-align:end;">
          <span>{{existingAccounts[0].name}}</span>
          <span>{{existingAccounts[0].detail}}</span>
        </label>
      </div>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12" style="text-align:center;"> 
      <label class="col-md-6 col-sm-6 col-xs-6">
        <button class="cancelButton" style="padding:5% 10%;width:100%; text-decoration:none;"  (click)="cancel()">{{'common.cancel' | translate}}</button>
      </label>
      <label class="col-md-6 col-sm-6 col-xs-6">
        <button class="connectButton" style="padding:5% 10%;width:100%; text-decoration:none;"  (click)="connect(0)">{{'common.connect' | translate}}</button>
      </label>
    </div>
  </div>

  <!-- CONNECT TO MULTIPLE ACOOUNT -->
  <div class="auth-block center-div box" style="padding:2% 0%;" *ngIf="selectedAccountToConnect == null && existingAccounts.length > 1">
    <div class="main">
      <div style="text-align: center;padding: 0px 0px 0px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <h1>{{'login.multiple_accounts' | translate}}</h1>
      <p>{{'login.choose_account' | translate}}</p>

      <div style="overflow-y: scroll;max-height: 250px;transform: translate(20%,0%);">
        <div *ngFor="let account of existingAccounts; let i = index;" class="col-md-12 col-sm-12 col-xs-12 accountDetailsStyle">
          <label class="col-md-5 col-sm-5 col-xs-5" style="display:inline-grid;padding:0% 5%;text-align:start;width:40%;">
            <span>{{'login.Org_name' | translate}}:</span>
            <span *ngIf="!normalRegister">{{'login.BusinessID_msg' | translate}}:</span>
            <span *ngIf="normalRegister">{{'login.projectId' | translate}}:</span>
          </label>
          <label class="col-md-4 col-sm-4 col-xs-4" style="display:inline-grid;padding:0% 5%;text-align:end;width:40%;">
            <span>{{account.name}}</span>
            <span>{{account.detail}}</span>
          </label>
          <label class="col-md-3 col-sm-3 col-xs-3" style="padding:0% 5%;width:20%;">
            <button class="connectButton" (click)="connect(i)">{{'common.connect' | translate}}</button>
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- SELECTED ACOOUNT -->
  <div class="auth-block center-div box" style="padding:2% 10%;" *ngIf="selectedAccountToConnect !== null && existingAccounts.length == 0">
    <div class="main">
      <div style="text-align: center;padding: 0px 0px 0px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <h1>{{'login.connect_account' | translate}}</h1>

      <div class="col-md-12 col-sm-12 col-xs-12 accountDetailsStyle" style="width:100%;">
        <label class="col-md-5 col-sm-5 col-xs-5" style="display:inline-grid;text-align:start;">
          <span>{{'login.Org_name' | translate}}:</span>
          <span *ngIf="!normalRegister">{{'login.BusinessID_msg' | translate}}:</span>
          <span *ngIf="normalRegister">{{'login.projectId' | translate}}:</span>
        </label>
        <label class="col-md-7 col-sm-7 col-xs-7" style="display:inline-grid;text-align:end;">
          <span>{{selectedAccountToConnect.name}}</span>
          <span>{{selectedAccountToConnect.detail}}</span>
        </label>
      </div>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12" style="text-align:center;display:inline-flex;padding:0px;"> 
      <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;margin-right:1%;">
        <button class="cancelButton" style="width:100%;padding:5% 10%;text-decoration: none;" (click)="cancel()">{{'common.cancel' | translate}}</button>
      </label>
      <label class="col-md-6 col-sm-6 col-xs-6" style="padding:0%;">
        <button class="connectButton" style="width:100%;padding:5% 10%;" (click)="signup()">{{'login.proceed' | translate}}</button>
      </label>
    </div>
  </div>

  <!-- SUCCESSFUL LOGIN -->
  <div class="auth-block center-div box" style="padding:2% 10%;" *ngIf="isLoggedIn && selectedAccountToConnect == null && existingAccounts.length == 0">
    <div class="main" *ngIf="!normalRegister">
      <div style="text-align: center;padding: 0px 0px 0px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <h1> {{'login.connection_info1' | translate}} <br/> {{'login.connection_info2' | translate}} </h1>
      <p>
        <span><strong>{{'login.connection_info3' | translate}}: </strong></span>
        {{'login.connection_info4' | translate}}
      </p>

      <em style="color:#ccc;" class="fas fa-check-circle fa-6x"></em>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="!normalRegister" style="text-align:center;display:inline-grid;padding:0px;padding-top:2%;"> 
      <label style="padding:0%;margin-right:1%;">
        <button class="connectButton" style="width:50%;padding:2% 0%;" (click)="appleBusinessRegister()">{{'login.goBack' | translate}}</button>
      </label>
    
    </div>
    <div class="main" *ngIf="normalRegister">
      <div style="text-align: center;padding: 0px 0px 0px 0px;width: 100%;">
          <img alt="" ng-src="as!sets/images/uib.png" height="60" src="assets/images/uib.png">
      </div>

      <h1> {{'login.connection_info1' | translate}} <br/> {{'login.connection_info2' | translate}} </h1>
      <em style="color:#ccc;" class="fas fa-check-circle fa-6x"></em>
    </div>


  </div>
</div>
