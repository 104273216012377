  <div class="datePicker1" style="float: right; display: flex;">
    <input class="form-control"
    #dp="bsDaterangepicker"
    bsDaterangepicker
    data-date-container='#myModalId'
    [(ngModel)]="bsRangeValue" placement="left"
    [bsConfig]="{containerClass: 'theme-dark-blue'}"
    (bsValueChange)="onValueChange($event)">
    <i (click)="dp.toggle()" style="margin-left: -17px;
    margin-top: 6px;
    color: white;" [attr.aria-expanded]="dp.isOpen" class="fa fa-chevron-down" aria-hidden="true"></i>
  </div>
