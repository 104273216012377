<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="padding:0px;">
    <app-page-header i18n-pageTitle="@@category_title" pageTitle="{{'rag.title' | translate}}" EngTitle="RAG"></app-page-header>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px; ">
    <div class="page-loader" *ngIf="loadershow">
        <img class="img1" alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px">
    </div>

    <div class="page-loader" *ngIf="imageUploadStatus">
        <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
    </div>

    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8" style="padding-left: 0px !important;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 conversation" style="padding:0px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sep parent" style="height: 100%;cursor:pointer;">
                    <div style="display: flex;cursor: pointer;font-size: 16px; padding-left: 21px;">
                        <div class="col-lg-6 col-md-6" (click)="selectedRAGChange('document')"
                            style="padding-right: 5px;">
                            <input style="cursor: pointer; display: inline;" type="radio" value="document" id="document"
                                class="radio_activity" [checked]="selectedRAGTab === 'document'" />
                            <a style="position: relative;  left: 10px;">{{'setting.document' |
                                translate}}</a>
                        </div>
                        <div class="col-lg-6 col-md-6" style="margin-left: 34px;"
                            (click)="selectedRAGChange('website')">
                            <input style="cursor: pointer; display: inline;" type="radio" value="website" id="website"
                                class="radio_activity" [checked]="selectedRAGTab === 'website'" />
                            <a style="margin-left: 10px;">{{'setting.website' | translate}}</a>
                        </div>
                    </div>
                    <div *ngIf="selectedRAGTab == 'document'"
                        style=" padding-top: 40px; margin-left: 35px;height: 200px; width: 280px;">
                        <input hidden type="file" accept=".pdf" class="form-control" [disabled]="uploadLoader"
                            (change)="handleFileUpload($event)" style="height: 40px;" #fileInput
                            title="Choose a PDF File">
                        <img *ngIf="uploadLoader" alt="" src="assets/images/dashboard/uib-loading-500x500.gif"
                            style="width: 8%;position: relative;left: 288px;bottom: 31px;" />
                            <em  [popover]="popUpRag" [placement]="'right'" [container]="'body'" [outsideClick]="true" class="fas fa-info-circle" triggers="mouseenter mouseleave click" style="position: relative; top: -30px; left: 293px; display: inline-block;"></em>
                    </div>
                    <div *ngIf="selectedRAGTab == 'website'"
                        style=" padding-top: 25px; margin-left: 35px;height: 200px; width: 280px;">
                        <div>
                            <label style="position:relative;top: 31px;left: 3px;">{{'setting.url' |
                                translate}}</label>
                            <div>
                                <input [disabled]="uploadUrlLoader" class="form-control" (keyup)="onKey($event)"
                                    type="text" [(ngModel)]="urlName" style="margin-left: 40px;width: 235px;">
                                <span style="position: relative;left: 288px;bottom: 25px; height: 15px; display: flex;">
                                    <img *ngIf="uploadUrlLoader" alt=""
                                        src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 6%;" />
                                </span>
                                <div style="margin-left: 75px;">
                                    <button class="btn-save commonButton" [disabled]="uploadUrlLoader"
                                        style="height: 35px; width: 143px; margin-top: 5px;"
                                        (click)="train()"> {{'setting.addAndTrain' |
                                        translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="col-lg-6 col-md-6" style="bottom: 40px;">
                    <div
                        style="height: 160px;width: 500px;margin-left: 14px;">
                        <div *ngFor="let item of documentFiles" style="height: 30px;">
                            <p
                                style="float: left;word-break: break-all;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 450px;">
                                <img src="assets/img/file-pdf-regular.svg" alt="" style="height: 14px;">
                                <a [href]="item['s3url']" target="_blank" rel="noopener noreferrer"
                                    style="position: relative;left: 5px; font-weight: 200;"
                                    title="{{ item.filename }}">{{
                                    item.filename }}</a>
                            </p>
                            <img *ngIf="item['trainingStatus'] == true" src="assets/images/check-circle-regular.svg"
                                style="width: 16px"
                                     alt="">
                            <img *ngIf="item['trainingStatus'] == false" src="assets/images/remove.png"
                                style="width: 16px;" alt="">
                            <img src="assets/img/cross.png" alt="" (click)="deleteRagDocument(item['_id'],item)" style="width: 16px;
                                  position: relative;
                                  left: 5px;">
                        </div>
                        <div [ngStyle]="{
                                'height': websiteFiles?.length > 3 ? '120px' : '',
                                'width': '504px',
                                'position':'relative',
                                'bottom':'10px',
                                'right':'0px'
                              }">
                            <div *ngFor="let item of websiteFiles">
                                <div style="clear: both; margin-bottom: 10px;">
                                    <div style="float: left; border-radius: 0px;">
                                        <p
                                            style="float: left;word-break: break-all;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 450px;">
                                            <img src="assets/img/globe.svg" alt="" style="height: 13px;">
                                            <a [href]="item['s3url']" target="_blank" rel="noopener noreferrer"
                                                style="position: relative;left: 5px; font-weight: 200;"
                                                title="{{ item.filename }}">{{
                                                item.filename }}</a>
                                        </p>
                                    </div>
                                    <div style="margin-top: 10px;">
                                        <img *ngIf="item['trainingStatus'] == true"
                                            src="assets/images/check-circle-regular.svg"
                                            style="width: 16px" alt="">
                                        <img *ngIf="item['trainingStatus'] == false" src="assets/images/remove.png"
                                            style="width: 16px" alt="">
                                        <img src="assets/img/cross.png" alt=""
                                            (click)="deleteRagDocument(item['_id'],item)" style="width: 16px;
                                          position: relative;
                                          left: 5px;">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <ng-template #popUpRag>
        <div class="card" style="border:none;max-height: 250px;">
            <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
                <p class="card-text" style="font-size: 12px;" innerHtml="{{'setting.rag_info' | translate}}">
                </p>
            </div>
        </div>
      </ng-template>
</div>
