import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule , HttpClient} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoaderService } from './loader/loader.service';
import { TitleService } from './title.service';
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeId from '@angular/common/locales/id';
import localeEn from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExceptionHandlerService } from './exception-handler.service';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { QuillModule } from 'ngx-quill';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { UniversalDeviceDetectorService } from './universal-device-detector.service';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEn, 'en');
registerLocaleData(localeId, 'id');

export function localeFactory(): string {
    return  navigator.language || window.navigator.language;
  }

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DashboardModule,
    AppRoutingModule,
    MatButtonModule,
     MatDialogModule,
     MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    MatTooltipModule,
    NgSelectModule,
    TimepickerModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    SelectDropDownModule,
    ContextMenuModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LoaderComponent],
  providers: [
    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService,

    },
    TitleService,
    DatePipe,
    LoaderService,
    NgbActiveModal,
    { provide: ErrorHandler, useClass: ExceptionHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
  }
}
