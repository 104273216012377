import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HomeService } from '../home.service';
import { Subscription } from 'rxjs';
import { MsgCenterService } from '../navbar/msg-center/msg-center.service';
import { Router, NavigationEnd } from '@angular/router';
import { SocketService } from '../socket.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { IconsService } from '../shared/icons.service';
import {TranslateService} from '@ngx-translate/core';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {
    screenWidth: number;
    disengageWindows: any;
    menuItems = [];
    dashboardPrefix = environment.dashboardPrefix;
    subscription: Subscription;
    menutabSubscription: Subscription;
    sideBarWidth = '270px';
    userName = null;
    userType = null;
    img_src: any;
    menutab: any;
    getVersion:any;
    appAgentHandover = false;
    setActive = false;
    translatorLanguage: any;
    conversationtab =     {
        'name' : 'Conversations',
        'path' : '/Conversations',
        'label': null,
        'icon' : 'conversations.png',
        'status' : true,
        'font_icon' : 'far fa-comment-alt',
        'sub' : [
            {
                'name' : 'Live Agents',
                'path' : '/liveagents',
                'label': null,
                'icon' : 'overview.png',
                'status' : true,
                'sub' : null,
                'font_icon' : ''
            },
            {
                'name' : 'Live Feed',
                'path' : '/Conversations',
                'label':  null,
                'icon' : 'overview.png',
                'status' : true,
                'sub' : null,
                'font_icon' : ''
            },
            {
                'name' : 'History',
                'path' : '/history',
                'label':  null,
                'icon' : 'overview.png',
                'status' : true,
                'sub' : null,
                'font_icon' : ''
            }
        ]
    };

    constructor(private router: Router, private toastr: ToastrService, private homeService: HomeService, private msgCenterService: MsgCenterService, private iconsService: IconsService, private socket: SocketService, private translate: TranslateService, private sharedService: SharedsService) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.setVersion();
    translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
        this.subscription = this.homeService.getMessage().subscribe(data => {
            this.handleMessageType(data);
        });
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            const res = event.url.split('/');
            if (res[2] === 'flow-builderv2' || res[2] === 'flow-builderv3') {

                this.setActive = true;
            } else {
                this.setActive = false;
            }
          });

            const r = this.router.url.split('/');
            if (r[1] === 'flow-builderv2' || r[1] === 'flow-builderv3') {

                this.setActive = true;
            } else {
                this.setActive = false;
            }

    }
    handleMessageType(data) {
        if (data.type === 'sideBar') {
            this.sideBarWidth = this.setValue(data.message, '270px', '70px');
        }
        if (data.type === 'profile') {
            this.img_src = localStorage.getItem('companyNameLogoUrl') ? JSON.parse(localStorage.getItem('companyNameLogoUrl')) : '';
        }
        if (data.type === 'menutab') {
            this.handleMenutab();
        }
    }
    handleMenutab() {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        this.menutab = localStorage.getItem('menutab') ? JSON.parse(localStorage.getItem('menutab')) : '';
        if (this.menutab) {
            this.menuItems =  this.menutab;
        } else if (userData?.roles?.menu_tab) {
            this.menuItems = userData.roles.menu_tab;
        } else { this.menuItems = []; }
        this.appAgentHandover = userData?.appSettings?.conversations?.agent_handover ?? false;

        if (this.appAgentHandover) {
            this.conversationtab.path = '/liveagents';
            const index = this.menuItems?.findIndex(item => item.name === 'Conversations');
            this.conversationtab = this.setMenubarLabel(this.menuItems, this.conversationtab);
            if (index > -1) {
                this.menuItems.splice(index, 1);
                this.menuItems.splice(index, 0, this.conversationtab);
            }
        }
    }
    // only on submenu
    menuToggle() {
        if (this.screenWidth < 750) {
            this.msgCenterService.toggleMenuSize(false);
        }
    }


    mainMenuCickToggle(menu) {
        if (this.screenWidth < 750 && menu?.sub && menu.sub.length > 1) {
            this.msgCenterService.toggleMenuSize(true);
        } else if (this.screenWidth < 750 && (menu?.sub && menu.sub.length < 1 || menu.sub == null) && this.sideBarWidth === '270px' ) {

            this.msgCenterService.toggleMenuSize(false);
        }
    }

    ngOnInit() {
        this.disengageWindows = document.getElementsByName('disengageWindows');
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        this.sharedService.getCompanyUrl().subscribe((result) => {
            this.img_src = result?.info?.url ?? './assets/img/uib-blue.png';
        });
        this.menutab = localStorage.getItem('menutab') ? JSON.parse(localStorage.getItem('menutab')) : '';
        this.menuItems = this.menutab && this.menutab !== '' ? this.menutab : userData.roles.menu_tab;
        this.appAgentHandover = userData.appSettings.conversations.agent_handover ? userData.appSettings.conversations.agent_handover : false;

        if (this.appAgentHandover) {
            this.conversationtab.path = '/liveagents';
            const index = this.menuItems.findIndex(item => item.name === 'Conversations');
            this.conversationtab = this.setMenubarLabel(this.menuItems, this.conversationtab);
            if (index > -1) {
                this.menuItems.splice(index, 1);
                this.menuItems.splice(index, 0, this.conversationtab);
            }
        }
        this.userName = userData.user_name;
        this.userType = userData.user_type;
        this.screenWidth = window.innerWidth;
        this.initResponsiveElements();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // Logout function
    logout(): void {
        if (this.homeService.agentOnlineStatus && this.homeService.agentOnlineStatus.messagelength > 0) {
            this.toastr.error(this.disengageWindows[0].innerText);
            this.router.navigate([environment.dashboardPrefix + '/liveagents']);

        } else {
            // Delete all items in local storage
            localStorage.clear();
            this.msgCenterService.logout().subscribe(response => {

            });
            this.socket.disconnect();
            // Go to login page
            this.router.navigate([environment.dashboardPrefix + '/login']);
        }
    }

    initResponsiveElements() {
        this.screenWidth = window.screen.width;
    }

    getMenuIcon(name) {
        return this.iconsService.getMenuIcon(name);
    }

    setMenubarLabel(menuItems, conversationtab) {
        menuItems.forEach(function(item) {
            if (item.name === 'Conversations') {
                conversationtab.label = item.label ? item.label : null;
            }
            if (item.name === 'Conversations' && item.sub.length) {
                item.sub.forEach(function(subitem) {
                    conversationtab.sub.map(function(tab) {
                        if (tab.name === subitem.name) {
                            tab.label = subitem.label ? subitem.label : null;
                        }
                    });
                });

            }
        });
        return conversationtab;
    }
    setValue(cond , val1 , val2) {
        if (cond) {
            return val1;
        } else {
            return val2;
        }
    }
    setVersion() {
        this.sharedService.setVersion().subscribe((res: any) => {
            this.getVersion = res.version
        })
    }
}
