

  <div style="display: flex;background-color:#333333" >
    <a class="al-logo clearfix" >
      <img alt="" src="assets/images/dashboard/unified-ai-logo-white.avif" style="width:108px;"/>
    </a>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 conversation" style="padding:2%; background-color: whitesmoke">
    <div class="page-loader" *ngIf="loadershow" style="left:50% !important; top:50% !important;">
      <img alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px">
    </div>

    <app-panel>
      <div style="height: fit-content;margin-bottom: 25px;">
        <div class="row pageHeight"  *ngIf="channels && channels.length > 0">
        <div class="container" style="display: flex;justify-content: center;">
          <div class="row" style="width:100%">
            <div *ngIf="!appNumberAbove2" class="col-md-3 col-sm-3" style="width: 22%;"></div>
            <div *ngFor="let app of userApps" class="col-md-3  col-sm-3 col-md-3  panel panel-default" style="cursor: pointer;margin:12px;" [ngStyle]="{width : appNumberAbove2 ? '30%' : '22%'}"  (click)="onGridClick(app)" [ngClass]= "appNumberAbove2 ? 'col-md-4' : 'col-md-6'">
                <div class="panel-body" style="text-align: center; " >
                  <img style="height: 175px;width:175px;" alt="" [src]="app['appIcon']"/>
                </div>
              <p style="text-align: center;font-weight: bold;">{{app.appName | uppercase}}</p>
            </div>
          </div>
        </div>
        </div>

        <div class="row pageHeight" style="height: 70vh;" *ngIf="channels && channels.length == 0">
          <div class="container" style="display: flex;justify-content: center;">
            <div class="row" style="width:100%">
              <div class="col-md-3" style="width: 22%;"></div>
                <h2 style="margin: 180px 0 0 180px;">Please contact support@uib.ai to add messaging channels</h2>
            </div>
          </div>
        </div>
      </div>
    </app-panel>
  </div>

