<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="background-image:url('assets/images/dashboard/login.png'); height:100vh;">
<div  class="auth-block center-div" style="text-align:center;background-color:white; border-radius:10px;padding:2%;">
    <div style="text-align: center;padding: 0px 0px 40px 0px;width: 100%;">
        <img alt="" ng-src="assets/images/dashboard/unified-ai-logo-blue.avif" height="50" src="assets/images/dashboard/unified-ai-logo-blue.avif">
    </div>

    <div *ngIf="!isUserValid && (errorMessage !== '' && errorMessage !== null && errorMessage !== undefined)" style="color:red;font-size:16px;">
        <em class="fas fa-exclamation-triangle"></em>
        &nbsp; {{errorMessage}}
        <br/>
        <a href="/login" style="text-decoration:none;cursor:pointer;color: #1e87f0;">{{'password.click_here_to_go_to_login_page' | translate}}</a>
    </div>

    <div *ngIf="!showLoader && userCreated">
        <span>
            <a href="/login" style="text-decoration:none;cursor:pointer;color: #1e87f0;">{{'password.click_here_to_go_to_login_page' | translate}}</a>
        </span>
    </div>

    <div *ngIf="isUserValid && !userCreated && showLoader" style="padding-left:5% !important;" class="lds-ring">
        <img alt="" src="assets/images/uib-loader.gif" style="width: 100px">
    </div>
    <div *ngIf="isUserValid && !userCreated && !showLoader" class="auth-block-body">
        <div *ngIf="!hideSetPasswordDialogBox">
            <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="padding: 0% 5%;display:grid;">
                    <div class="form-group">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" [class.error-textbox]="error && error['status'] === true">
                          <label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 12px;width: inherit;margin-bottom: 0px;">{{'password.set_password' | translate}}</label>
                         <div>
                        <div class="input-group-prepend">
                        <span class="input-group-text"><em class="fas fa-lock" style="color:black;font-size: medium;"></em></span>
                        </div>
                          <input type="password" id="newPassword" class="form-control" formControlName="newPassword" name="newPassword" placeholder="{{'password.new_password_placeholder' | translate}}" style="padding-left: 10%;border-radius: 5px;color:#333333 !important;">
                        </div>
                          <span [ngStyle]="{'visibility': (submitted && f.newPassword.errors && f.newPassword.errors.required) ? 'visible':'hidden'}">
                            {{'password.new_password_required' | translate}}
                            </span>
                            <span [ngStyle]="{'visibility': (submitted && f.newPassword.errors && f.newPassword.errors.pattern) ? 'visible':'hidden'}">{{'user_management.valid_password' | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" [class.error-textbox]="error && error['status'] === true">
                          <label style="color: #333333;font-family: 'WorkSansLight', sans-serif;text-align: start;font-size: 12px;width: inherit;margin-bottom: 0px;">{{'password.confirm_password_placeholder' | translate}}</label>
                          <div>
                        <div class="input-group-prepend">
                          <span class="input-group-text"><em class="fas fa-lock" style="color:black;font-size: medium;"></em></span>
                        </div>
                          <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword" name="confirmPassword" placeholder="{{'password.confirm_password_placeholder' | translate}}" style="padding-left: 10%;border-radius: 5px;color:#333333 !important;">
                        </div>

                          <span [ngStyle]="{'visibility': (submitted && f.confirmPassword.errors) ? 'visible':'hidden'}">
                            {{'password.passwords_do_not_match' | translate}}
                            </span>
                        </div>
                    </div>
                    <div style="padding-top: 70px;">
                        <span>
                            <ul style="font-size: 11px;font-style: italic;color: #828282;list-style-type:disc;padding: 6px 18px;">
                                <li>{{'user_management.password_minimum_length' | translate}}</li>
                                <li>{{'user_management.password_uppercase_letters' | translate}}</li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div class="form-group" style="margin-bottom:0px; padding: 20% 5% 0%;">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <button class="btn-default btn-auth" type="submit" style="background-color: #386BA7;border-radius:5px;box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, .2), 0 0 14px 0 rgba(30, 135, 240, .3);">
                        {{'common.submit' | translate}}
                    </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
